import { eventNames } from "../../../utils/constants/events";
import { images } from "../../../utils/constants/images";

export const regAssests = [
  {
    language: "en",
    assets: [
      {
        event: eventNames.regUnfilledStep,
        img: images.masterUnfilledSteps,
      },
      {
        event: eventNames.regStep1,
        img: images.masterStep1,
      },
      {
        event: eventNames.regStep2,
        img: images.masterStep2,
      },
      {
        event: eventNames.regStep3,
        img: images.masterStep3,
      },
    ],
  },
  {
    language: "es",
    assets: [
      {
        event: eventNames.regUnfilledStep,
        img: images.esUnfilledSteps,
      },
      {
        event: eventNames.regStep1,
        img: images.esStep1,
      },
      {
        event: eventNames.regStep2,
        img: images.esStep2,
      },
      {
        event: eventNames.regStep3,
        img: images.esStep3,
      },
    ],
  },
];
