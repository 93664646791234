import { Box, Typography } from "@mui/material";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  FilteredApiData,
  Loc,
  Locale,
  setLoading,
  User,
} from "../../../utils/redux";
import OrderCardHistory from "./OrderHistoryCard";
import { downloadExportFile } from "../../../utils/helperFunctions/helperFunction";
import Config from "../../../utils/config";
import { URLS } from "../../../utils/constants/urls";
import axiosInstance from "../../../utils/axios";
import { sendEvent } from "../../../utils/helperFunctions/sendEvent";
import { OrderHistory as OrderHistoryInterface } from "../../../utils/types";

interface OrderHistoryProps {
  handleEditIcon?: (orderData: any) => void;
  setDeleteOrderId?: Dispatch<SetStateAction<null>>;
  setMessageType?: Dispatch<SetStateAction<string>>;
  setShowPopUp?: Dispatch<SetStateAction<boolean>>;
  setPopUpMessage?: Dispatch<SetStateAction<string>>;
  deleteMessage?: any;
  isPeriodOrder?: boolean;
  labels?: any;
  periodOpenOrders?: OrderHistoryInterface[];
  periodCloseOrders?: OrderHistoryInterface[];
  currentPeriod?: any;
}

const OrderHistory: FC<OrderHistoryProps> = ({
  handleEditIcon,
  setDeleteOrderId,
  setMessageType,
  setPopUpMessage,
  setShowPopUp,
  deleteMessage,
  isPeriodOrder,
  labels,
  periodOpenOrders,
  periodCloseOrders,
  currentPeriod,
}) => {
  const locale = useSelector(Locale);
  const filtredData = useSelector(FilteredApiData);
  const [openOrders, setOpenOrders] = useState<OrderHistoryInterface[]>([]);
  const [closeOrders, setCloseOrders] = useState<OrderHistoryInterface[]>([]);
  const user = useSelector(User);
  const loc = useSelector(Loc);
  const dispatch = useDispatch();

  const downloadOrderHistory = async () => {
    const phone = `phoneNumber:cic:${user.phone}`;
    const locName = `loc:cic:${loc}`;
    const period = `period:cic:${currentPeriod}`;
    dispatch(setLoading(true));
    axiosInstance({
      url: `${Config.BaseURL}${URLS.downloadOrderHistory}`,
      method: "post",
      responseType: "blob",
      data: {
        filters: isPeriodOrder
          ? [period, "AND", phone, "AND", locName]
          : [phone, "AND", locName],
      },
    })
      .then((response: any) => {
        dispatch(setLoading(false));
        downloadExportFile(response, "xlsx", "download_history");
      })
      .catch((err: any) => {
        dispatch(setLoading(false));
      });
  };
  
  useEffect(() => {
    if (isPeriodOrder) {
      if (periodOpenOrders) {
        setOpenOrders(periodOpenOrders);
      }
      if (periodCloseOrders) {
        setCloseOrders(periodCloseOrders);
      }
    } else {
      setOpenOrders(filtredData.openOrders);
      setCloseOrders(filtredData.closeOrders);
    }
  }, [isPeriodOrder, periodOpenOrders, periodCloseOrders, filtredData]);

  return (
    <Box className="p20">
      <Box className="relative flex ai-c jc-sb pb15">
        {isPeriodOrder ? (
          <Box className="f1 flex jc-c ai-c">
            <button
              className="font12 new-order-button"
              onClick={() => {
                sendEvent("new-order-clicked", {});
              }}
            >
              {labels?.new_order_button}
            </button>
          </Box>
        ) : (
          <Typography ml={"2px"} color="primary.main" fontSize={16}>
            {locale.labels.orderHistory}
          </Typography>
        )}
        <CustomButton
          onClick={downloadOrderHistory}
          width="45px"
          align="center"
          padding="5px 4px"
        >
          <FileDownloadOutlinedIcon fontSize="small" />
        </CustomButton>
      </Box>

      {openOrders.length === 0 && closeOrders.length === 0 && (
        <Box className="mt30">
          <Typography fontSize={15} textAlign={"center"} color="secondary.main">
            {locale.labels.emptyOrder}
          </Typography>
        </Box>
      )}
      {openOrders && openOrders.length > 0 && (
        <Typography fontWeight={"bold"} fontSize={15} pb={"8px"} pt={"2px"}>
          {locale.checkoutForm.open_order}
        </Typography>
      )}

      {openOrders &&
        openOrders.map((order, index: number) => {
          return (
            <OrderCardHistory
              key={index}
              type={"open"}
              orderData={order}
              formType={"type1"}
              showIcons={true}
              handleEditIcon={handleEditIcon}
              setDeleteOrderId={setDeleteOrderId}
              setMessageType={setMessageType}
              setShowPopUp={setShowPopUp}
              setPopUpMessage={setPopUpMessage}
              deleteMessage={deleteMessage}
            />
          );
        })}

      {closeOrders && closeOrders.length > 0 && (
        <Box className="close-order-title">
          <Typography fontSize={15} fontWeight={"bold"}>
            {locale.checkoutForm.close_order}
          </Typography>
        </Box>
      )}

      {closeOrders &&
        closeOrders.map((order: any, index: number) => {
          return (
            <OrderCardHistory
              key={index}
              type={"close"}
              orderData={order}
              formType={"type1"}
              showIcons={false}
            />
          );
        })}
    </Box>
  );
};

export default OrderHistory;
