import { discountType } from "../constants/discount-types";
import { FiltredApiDataInterface } from "../redux/reducer/order-slice";
import { DiscountRanges, OfferApiData, OrderHistory } from "../types";

const getFilteredOrderHistory = (data: OfferApiData, statusToCheck: string) => {
  const updatedOrderHistory = data.orderHistory
    .filter(
      (ele) =>
        ele.internalOrderStatus &&
        ele.internalOrderStatus.toLowerCase() === statusToCheck
    )
    .sort((a, b) => {
      const dateA = new Date(a.orderedDateString);
      const dateB = new Date(b.orderedDateString);
      return dateB.getTime() - dateA.getTime();
    });

  return updatedOrderHistory;
};

const updateDiscountRanges = (DiscountRanges: DiscountRanges[]) => {
  const updatedRange: DiscountRanges[] = [];
  if (DiscountRanges) {
    for (let i = 0; i < DiscountRanges.length - 1; i++) {
      updatedRange.push({
        discount: DiscountRanges[i + 1].discount,
        label: DiscountRanges[i].label,
      });
    }
  }
  return updatedRange;
};

export const getFilteredOffersData = (data: OfferApiData) => {
  try {
    let brandAdded: string[] = [];
    let brandList: FiltredApiDataInterface[] = [];
    let openOrders: OrderHistory[] = [];
    let closeOrders: OrderHistory[] = [];
    let offerData = [];
    let lastOrder: OrderHistory | null = null;
    let startDate = "";
    let endDate = "";

    if (data) {
      if (data.clubbedOffers && data.clubbedOffers.length > 0) {
        for (let offerData of data.clubbedOffers) {
          if (
            offerData.brandIdentifier &&
            !brandAdded.includes(offerData.brandIdentifier)
          ) {
            brandAdded.push(offerData.brandIdentifier);

            let onInvoiceDiscountRanges: DiscountRanges[] = [];
            let offInvoiceDiscountRanges: DiscountRanges[] = [];

            if (offerData.discountExecution === discountType.on_invoice) {
              onInvoiceDiscountRanges = offerData.onInvoiceDiscountRanges;
            } else if (offerData.discountExecution === discountType.off_invoice) {
              offInvoiceDiscountRanges = offerData.offInvoiceDiscountRanges;
            } else if (offerData.discountExecution === discountType.both) {
              onInvoiceDiscountRanges = offerData.onInvoiceDiscountRanges;
              offInvoiceDiscountRanges = offerData.offInvoiceDiscountRanges;
            }

            brandList.push({
              brand: offerData.brand,
              brandIdentifier: offerData.brandIdentifier,
              discountExecution: offerData.discountExecution,
              onInvoiceDiscountRanges,
              offInvoiceDiscountRanges,
              purchased: 0,
              selected: 0,
              offerLevel: offerData.offerLevel,
              skuData: [],
              maxVolume: offerData.maxVolume,
            });
          }
        }

        for (let brand of brandList) {
          const skuData = data.clubbedOffers
            .filter(
              (offerData) =>
                offerData.skuIdentifier &&
                offerData.brandIdentifier === brand.brandIdentifier
            )
            .map((offerData) => {
              return {
                allowBonus: offerData.allowBonus,
                brand: offerData.brand,
                brandIdentifier: offerData.brandIdentifier,
                dailyOffer: offerData.dailyOffer,
                discountExecution: offerData.discountExecution,
                ean: offerData.ean,
                maxLevelReached: offerData.maxLevelReached,
                maxVolume: offerData.maxVolume,
                noDiscountPercentage: offerData.noDiscountPercentage,
                offInvoiceDiscountRanges: offerData.offInvoiceDiscountRanges,
                offerLevel: offerData.offerLevel,
                onInvoiceDiscountRanges: offerData.onInvoiceDiscountRanges,
                period: offerData.period,
                previouslyOrdered: offerData.previouslyOrdered,
                sku: offerData.sku,
                skuIdentifier: offerData.skuIdentifier,
              };
            });
          offerData.push({
            ...brand,
            skuData: skuData,
          });
        }
        startDate = data.clubbedOffers[0].period.startDateString;
        endDate = data.clubbedOffers[0].period.endDateString;
      }
      if (data.orderHistory) {
        openOrders = getFilteredOrderHistory(data, "open");
        closeOrders = getFilteredOrderHistory(data, "closed");
        if (data.orderHistory.length > 0) {
          lastOrder = data.orderHistory[data.orderHistory.length - 1];
        }
      }
    }

    return {
      lastOrder,
      openOrders,
      closeOrders,
      offerData,
      startDate,
      endDate,
    };
  } catch (error) {
    return {
      openOrders: [],
      closeOrders: [],
      offerData: [],
      startDate: "",
      endDate: "",
      lastOrder: null,
    };
  }
};

export const findLocaleLang = (data: any, language?: any) => {
  let defaultLang = data?.defaultLang;
  if (localStorage.getItem("current-lang")) {
    defaultLang = localStorage.getItem("current-lang");
  } else {
    localStorage.setItem("current-lang", defaultLang);
  }
  if (language) {
    defaultLang = language;
    localStorage.setItem("current-lang", language);
  }
  const localeData = data?.localization.find(
    (langData: any) => langData?.language === defaultLang
  );
  return localeData;
};

export const downloadExportFile = (response: any, type: any, fileName: any) => {
  const url = window.URL.createObjectURL(new Blob([response.data || response]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("target", `_blank`);
  if (response.headers["content-disposition"].split("filename=")[1]) {
    link.setAttribute(
      "download",
      response.headers["content-disposition"]
        .split("filename=")[1]
        .split('"')[1]
    );
  } else {
    link.setAttribute("download", `${fileName || "Export"}.${type}`);
  }

  document.body.appendChild(link);
  link.click();
};
