import { useSelector } from "react-redux";
import { sendEvent } from "../../../utils/helperFunctions/sendEvent";
import CheckoutFormTabs from "./FormTabs";
import { useEffect, useState } from "react";
import { BotEventData, Locale } from "../../../utils/redux";
import { checkoutFormLabels } from "./checkoutFormLabels";

const CheckoutForm = () => {
  const locale = useSelector(Locale);
  const [sendHistoryTabEvent, setSendHistoryTabEvent] = useState(true);
  const [isReloaded, setIsReloaded] = useState(false);
  const [editOrderData, setEditOrderData] = useState(null);
  const [refreshComponent, setRefreshComponent] = useState(false);
  const [reload, setReload] = useState(0);
  const [labels, setLabels] = useState({});
  const botEventData = useSelector(BotEventData);
  const [activeTab, setActiveTab] = useState(
    botEventData?.orderflow?.toUpperCase() === "edit" ? 2 : 1
  );

  useEffect(() => {
    if (locale.language) {
      const labelData = checkoutFormLabels.find(
        (data) => data.language === locale.language
      );
      if (labelData) {
        setLabels(labelData);
      }
    }
  }, [locale.language]);

  useEffect(() => {
    if (reload > 0) {
      setRefreshComponent(true);
    }
  }, [reload]);

  useEffect(() => {
    if (refreshComponent) {
      setEditOrderData(null);
      setRefreshComponent(false);
    }
  }, [refreshComponent]);

  useEffect(() => {
    if (botEventData?.orderflow === "edit") {
      setReload(reload+1);
      setActiveTab(2);
    } else {
      setActiveTab(1);
      setReload(reload+1);
      setIsReloaded(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botEventData]);

  return (
    <div className="main-container">
      <div className="flex g10">
        <span
          className={`flex f1 ai-e order-tab ${
            activeTab === 1 ? "active-tab" : "inactive-tab"
          }`}
          onClick={() => {
            if (activeTab !== 1 && isReloaded && !editOrderData) {
              sendEvent("new-order-clicked", {});
            } else if (
              activeTab !== 1 &&
              !editOrderData &&
              botEventData?.orderflow === "edit"
            ) {
              sendEvent("new-order-clicked", {});
            } else {
              setActiveTab(1);
            }
          }}
        >
          {labels.order_details?.toUpperCase()}
        </span>
        <span
          className={`flex f1 ai-e order-tab ${
            activeTab === 2 ? "active-tab" : "inactive-tab"
          }`}
          onClick={() => {
            setActiveTab(2);
            if (activeTab !== 2 && sendHistoryTabEvent) {
              sendEvent("order-history-tab", {});
              setSendHistoryTabEvent(false);
            }
          }}
        >
          {labels.period_orders?.toUpperCase()}
        </span>
      </div>
      {!refreshComponent && (
        <CheckoutFormTabs
          labels={labels}
          setIsReloaded={setIsReloaded}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          editOrderData={editOrderData}
          setEditOrderData={setEditOrderData}
          reload={reload}
          setReload={setReload}
        />
      )}
    </div>
  );
};

export default CheckoutForm;
