// const devUrl = process.env.REACT_APP_ENV === 'DEV';
// const stageUrl = process.env.REACT_APP_ENV === 'STAGE';

// console.log(process.env)

const Config = {
    BaseURL: 'https://devadmin.gskassist.com/yelo', //dev
    // BaseURL: 'https://stagingadmin.gskassist.com/yelo', //stage
    // BaseURL: devUrl ? `${window.location.protocol}//${window.location.hostname}:8097` : 
    //          stageUrl? `${window.location.protocol}//${window.location.hostname}/yelo`: 
    //          `${window.location.protocol}//${window.location.hostname}/`,
};

export default Config