import { languages } from "../../../utils/constants/languages";
export const checkoutFormLabels = [
  {
    language: languages.master,
    deleteOrderMessage: "Do you want to delete this order?",
    ok_button: "Ok",
    order_details: "ORDER DETAILS",
    period_orders: "PERIOD ORDERS",
    editing_order: "EDITING ORDER",
    noOfPhar: "Number of Pharmacies",
    wholesaler_placeholder: "Select Wholesaler",
    wholesaler_error: "Please select wholesaler",
    date_error: "Please select date",
    filter_placeholder: "Filter products…",
    date_placeholder: "Select Date",
    cleara_button: "CLEAR",
    no_date_available: "No date available",
    products_th: "Products",
    units_th: "Units",
    discount: "Discount",
    total_value: "Total",
    table_discount: "Discount",
    total_discount: "Discount",
    final_value: "Value",
    confirm_button: "CONFIRM",
    cancel_button: "CANCEL",
    unit_select_error: "Please select at least 1 product!",
    order_confirm_message: "Confirm to place this order",
    cancel_message: "Do you want to cancel this order?",
    order_success_message: "Your order is placed successfully!",
    order_no_history: "Order No",
    date_history: "Date",
    order_details_history: "Order Details",
    sku_th_history: "Product",
    units_th_history: "Units",
    on_invoice_discount_history: "Discount",
    placing_new_order: "PLACING NEW ORDER",
    new_order_button: "NUEVO TRANSFER",
    no_of_phar_error: "",
    cancel_order: "CANCEL ORDER",
    keep_order: "KEEP ORDER",
    confirm_pop_up_button: "CONFIRM",
    go_back: "CANCEL",
    sku_th: "SKU",
    total_units: "Units",
    price: "Price",
    currency: "$",
    open_order: "OPEN ORDER",
    close_order: "CLOSE ORDER",
    edit_tooltip: "Edit",
    delete_tooltip: "Delete",
    download_tooltip: "Download",
    more_details_tooltip: "More Details",
    no_order_history: "No order found",
    order_delete_success: "order_id_here with order id deleted!",
    filter_error: "No product available",
    api_error: "Something went wrong!",
    maxVolumeError: "Max sku allowed is number_of_max_volume",
    edit_button: "MODIFY",
    drop_changes: "Drop changes?",
    drop_changes_yes: "Yes",
    drop_changes_no: "No",
  },
  {
    language: languages.portugal,
    deleteOrderMessage: "Tem certeza de que deseja APAGAR esta encomenda?",
    ok_button: "VOLTAR",
    order_details: "Detalhes da encomenda",
    period_orders: "ENCOMENDAS DO MÊS",
    editing_order: "Editando a encomenda",
    noOfPhar: "Número de Farmácias:",
    wholesaler_placeholder: "Selecione seu ARMAZENISTA",
    wholesaler_error: "Por favor selecione seu ARMAZENISTA",
    date_error: "Por favor selecione a DATA",
    filter_placeholder: "Digite aqui para filtrar produtos…",
    date_placeholder: "Selecione a DATA",
    cleara_button: "LIMPAR",
    no_date_available: "Por favor edite seu encomendas em aberto",
    products_th: "Produtos",
    units_th: "Unidades",
    discount: "Descontos",
    total_value: "Valor",
    table_discount: "Desc %",
    total_discount: "Desconto em fatura",
    final_value: "Valor final da fatura",
    confirm_button: "CONFIRMAR",
    cancel_button: "VOLTAR",
    unit_select_error: "Por favor adicione algumas unidades!",
    order_confirm_message:
      "Por favor selecione CONFIRMAR ENCOMENDAS para que sejam enviadas",
    cancel_message: "Tem certeza de que deseja CANCELAR esta encomenda?",
    order_success_message: "Obrigado. As suas encomendas foram confirmadas.",
    api_error:
      "Algo deu errado. Clique no botão Ajuda (?) para entrar em contato com o Atendimento ao Cliente.",
    order_no_history: "Encomenda",
    date_history: "Data",
    order_details_history: "DETALHES DA ENCOMENDA",
    sku_th_history: "Produtos",
    units_th_history: "Unidades",
    on_invoice_discount_history: "Desconto %",
    filter_error: "Produto não disponível",
    placing_new_order: "Colocando uma nova encomenda",
    new_order_button: "NOVA ENCOMENDA",
    no_of_phar_error: "Por favor digite seu Número de Farmácias",
    cancel_order: "SIM / APAGAR ENCOMENDA",
    keep_order: "NÃO / MANTER ENCOMENDAS",
    confirm_pop_up_button: "CONFIRMAR",
    go_back: "VOLTAR",
    sku_th: "",
    total_units: "Unidades Totales",
    price: "Preço",
    currency: "€",
    open_order: "ENCOMENDAS EM ABERTO",
    close_order: "ENCOMENDAS FECHADAS",
    edit_tooltip: "Editar",
    delete_tooltip: "Apagar",
    download_tooltip: "Descarga",
    more_details_tooltip: "Detalhes",
    no_order_history: "Não há encomendas para o mês",
    order_delete_success: "A Encomenda order_id_here foi apagada",
    maxVolumeError: "O máximo de unidades é number_of_max_volume",
    edit_button: "MODIFICAR",
    drop_changes:
      "Tem certeza de que deseja cancelar as modificações nesta encomenda?",
    drop_changes_yes: "SIM / CANCELAR MODIFICAÇÕES",
    drop_changes_no: "NÃO / MANTER MODIFICAÇÕES",
  },
  {
    language: languages.spanish,
    deleteOrderMessage: "¿Está seguro que desea CANCELAR este transfer?",
    ok_button: "VOLVER",
    order_details: "DETALLES DEL TRANSFER",
    period_orders: "TRANSFERS DEL MES",
    editing_order: "Editando el Transfer",
    noOfPhar: "",
    wholesaler_placeholder: "Seleccione su Mayorista",
    wholesaler_error: "Por favor seleccione su MAYORISTA",
    date_error: "Por favor seleccione la FECHA",
    filter_placeholder: "Digite aqui para filtrar productos…",
    date_placeholder: "Seleccione la fecha",
    cleara_button: "LIMPIAR",
    no_date_available: "Por favor edite sus ordenes del Mes",
    products_th: "Productos",
    units_th: "Unidades",
    discount: "",
    total_value: "",
    table_discount: "",
    total_discount: "",
    final_value: "",
    confirm_button: "CONFIRMAR",
    cancel_button: "VOLVER",
    unit_select_error: "Por favor agregue alguna unidad!",
    order_confirm_message:
      "Por favor seleccione CONFIRMAR para que su transfer sea enviado",
    cancel_message: "¿Está seguro que desea CANCELAR este Transfer?",
    order_success_message: "Gracias. Su pedido há sido confirmado.",
    order_no_history: "Transfer No",
    date_history: "Fecha",
    order_details_history: "Detalles del Transfer",
    sku_th_history: "Productos",
    units_th_history: "Unidades",
    on_invoice_discount_history: "",
    placing_new_order: "Nuevo pedido de Transfer",
    new_order_button: "NUEVO TRANSFER",
    no_of_phar_error: "",
    cancel_order: "SI / CANCELAR EL TRANSFER",
    keep_order: "NO / MANTENER EL TRANSFER",
    confirm_pop_up_button: "CONFIRMAR",
    go_back: "VOLVER",
    sku_th: "CN",
    total_units: "Unidades Totales",
    price: "",
    currency: "",
    open_order: "TRANSFERS ABIERTOS",
    close_order: "TRANSFERS CERRADOS",
    edit_tooltip: "Editar",
    delete_tooltip: "Cancelar",
    download_tooltip: "Descarga",
    more_details_tooltip: "Detalles",
    no_order_history: "No hay pedidos para el mes",
    order_delete_success: "El Transfer order_id_here ha sido eliminado",
    filter_error: "Producto no disponible",
    api_error: "Algo fallo. Por favor haga clic en el boton Ayuda (?) para contactar Atencion al Cliente.",
    maxVolumeError: "El maximo de unidades es number_of_max_volume",
    edit_button: "MODIFICAR",
    drop_changes:
      "¿Está seguro de que desea cancelar las modificaciones a este Transfer?",
    drop_changes_yes: "SI / CANCELAR LAS MODIFICACIONES",
    drop_changes_no: "NO / MANTENER LAS MODIFICACIONESR",
  },
];