import * as React from "react";
import "./index.css";
import { Box } from "@mui/material";
const ToggleButton = () => {
  const [isOn, setIsOn] = React.useState(true);
  return (
    <Box
      className="switch-outerBox"
      onClick={() => {
        setIsOn((prev) => !prev);
      }}
      sx={{
        background: isOn ? "#2ECA45" : "#FF4C50",
        justifyContent: isOn ? "flex-end" : "flex-start",
      }}
    >
      <Box className="switch-innerBox" />
    </Box>
  );
};

export default ToggleButton;
