import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FilteredApiData,
  Locale,
  setCurrentScreen,
} from "../../../utils/redux";
import LinearProgress from "@mui/material/LinearProgress";
import { FiltredApiDataInterface } from "../../../utils/redux/reducer/order-slice";
import "./index.css";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { screenNames } from "../../../utils/constants/screenNames";
import OrderCardHistory from "../OrderHistory/OrderHistoryCard";

const UserWelcomeScreen = () => {
  const locale = useSelector(Locale);
  const filtredData = useSelector(FilteredApiData);
  const dispatch = useDispatch();
  const calculateProgress = (progressData: FiltredApiDataInterface) => {
    return (progressData.purchased / progressData.maxVolume) * 100;
  };

  return (
    <Box className="p20">
      {filtredData.lastOrder && (
        <Box className="flex jc-sb">
          <Typography color="primary" fontSize={17}>
            {locale.labels.lastOrder}
          </Typography>
          <CustomButton
            onClick={() => {
              dispatch(setCurrentScreen(screenNames.orderHistory));
            }}
            fontSize={13}
            padding="0px 10px"
            width="fit-content"
          >
            {locale.labels.orderHistory}
          </CustomButton>
        </Box>
      )}
      {filtredData.lastOrder && (
        <Box className="mt15">
          <OrderCardHistory
            type={"open"}
            formType={"type1"}
            showIcons={false}
            orderData={filtredData.lastOrder}
          />
        </Box>
      )}

      {!filtredData.lastOrder && (
        <Typography fontSize={15} textAlign={"center"} color="secondary.main">
          {locale.labels.emptyOrder}
        </Typography>
      )}

      <Box className="flex jc-sb mt15">
        <Typography color="primary" fontSize={17}>
          {locale.labels.title}
        </Typography>
        {filtredData.offerData && filtredData.offerData.length > 0 && (
          <Box className="flex ai-c g5">
            <Typography fontSize={11} fontWeight={700} color="primary">
              {locale.labels.endDate}:
            </Typography>
            <Typography fontSize={11}>{filtredData.endDate}</Typography>
          </Box>
        )}
      </Box>
      <Box>
        <Grid container>
          <Grid item>
            {filtredData.offerData.map((offer) => {
              return (
                <Typography key={offer.brandIdentifier} fontSize={13.2} noWrap mt={1.5}>
                  {offer.brand}
                </Typography>
              );
            })}
          </Grid>
          <Grid xs item>
            {filtredData.offerData.map((offer) => {
              return (
                <LinearProgress
                  key={offer.brandIdentifier}
                  variant="determinate"
                  value={calculateProgress(offer)}
                  sx={{
                    marginTop: 1.5,
                    marginLeft: 1,
                    height: "20px",
                    backgroundColor: "secondary.main",
                    borderRadius: "5px",
                    "& .MuiLinearProgress-bar": {
                      transition: "none",
                      backgroundColor: "primary",
                      borderRadius: "5px",
                    },
                  }}
                />
              );
            })}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default UserWelcomeScreen;
