export const sxStyle = {
  orderHistoryCard: (type: string) => {
    return {
      backgroundColor: type === "open" ? "white" : "#3f3f3f",
      color: type === "open" ? "black" : "#959595",
      border: type === "open" ? "1px solid grey" : "",
    };
  },
  checkGreyColor: (type: string) => {
    return {
      color: type === "open" ? "#151515" : "#959595",
    };
  },
  moreDetails: (type: string) => {
    return {
      cursor: "pointer",
      color: type === "open" ? "black" : "white",
      fontSize: 23,
      marginRight: "5px",
    };
  },
  getStatusColor: (status: any, type = "", cardType: any) => {
    if (cardType === "close") {
      return "rgb(198, 196, 196)";
    }
    if (
      status === "IN PROCESS" ||
      status === "EM PROCESSO" ||
      status === "EN PROCESO"
    ) {
      return "rgb(0,0,0)";
    }
    if (status === "CONDITIONAL" || status === "CONDICIONAL") {
      if (type === "font") {
        return "rgb(191,144,0)";
      } else {
        return "rgb(255,192,0)";
      }
    }

    if (
      status === "AWAITING DATE" ||
      status === "EN ESPERA DE FECHA" ||
      status === "DATA PENDENTE"
    ) {
      return "rgb(68,114,196)";
    }

    if (status === "INVOICED" || status === "FACTURADO") {
      return "rgb(0,176,80)";
    }

    if (
      status === "CANCELLED" ||
      status === "CANELADO" ||
      status === "CANCELADA"
    ) {
      return "rgb(255,60,60)";
    }

    if (status === "SENT" || status === "ENVIADO" || status === "ENVIADA") {
      return "rgb(0,153,138)";
    }

    if (status === "EXPIRED" || status === "EXPIRADA") {
      return "rgb(153,102,255)";
    }

    if (
      status === "DRAFT" ||
      status === "NOT CONFIRMED" ||
      status === "NÃO CONFIRMADO" ||
      status === "NO CONFIRMADO"
    ) {
      return "rgb(166,166,166)";
    }
    return "black";
  },
};
