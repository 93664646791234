export const sendEvent = (eventName: string, data: any) => {
  const botIframe = document.getElementById("bot-container-iframe") as any;
  if (botIframe) {
    console.log("sendEvent::", {
      eventName: eventName,
      eventData: data,
    });

    botIframe.contentWindow.postMessage(
      JSON.stringify({
        event_code: "send-event-to-bot",
        data: {
          eventName: eventName,
          eventData: data,
        },
      }),
      "*"
    );
  }
};
