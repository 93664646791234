import { eventNames } from "../constants/events";
import { screenNames } from "../constants/screenNames";
import store, { setAuthentication, setBotEventData, setCurrentScreen, setUser } from "../redux";
import { logout } from "../redux/reducer/authentication-slice";
import { sendEvent } from "./sendEvent";

export const handleEvents = (
  event: any,
  callback: (screenName: string, data: any) => void
) => {
  try {
    console.log("EventData::", event);

    if (event.eventName === eventNames.welcomeScreen) {
      store.dispatch(setCurrentScreen(screenNames.welcomeScreen));
      store.dispatch(logout());
    }
    if (event.eventName === eventNames.userLogin) {
      if (event?.data?.token) {
        store.dispatch(setUser(event?.data));
        store.dispatch(setAuthentication(event?.data?.token));
      }
      store.dispatch(setCurrentScreen(screenNames.userWelcomeScreen));
    }

    if (event.eventName === eventNames.botLoadLanguage) {
      const currentLang = localStorage.getItem("current-lang");
      sendEvent(eventNames.loadLanguage, {
        language: currentLang,
      });
    }

    if (event.eventName === eventNames.userWelcomeScreen) {
      store.dispatch(setCurrentScreen(screenNames.userWelcomeScreen));
    }

    if (event.eventName === eventNames.language) {
      callback(eventNames.language, event.data);
    }

    if (event.eventName === eventNames.orderHistory) {
      store.dispatch(setCurrentScreen(screenNames.orderHistory));
    }

    if (event.eventName === eventNames.regUnfilledStep) {
      store.dispatch(setCurrentScreen(eventNames.regUnfilledStep));
    }

    if (event.eventName === eventNames.regStep1) {
      store.dispatch(setCurrentScreen(eventNames.regStep1));
    }

    if (event.eventName === eventNames.regStep2) {
      store.dispatch(setCurrentScreen(eventNames.regStep2));
    }

    if (event.eventName === eventNames.regStep3) {
      store.dispatch(setCurrentScreen(eventNames.regStep3));
    }

    if (event.eventName === eventNames.termCondition) {
      store.dispatch(setCurrentScreen(screenNames.termAndCondition));
    }

    if (event.eventName === eventNames.checkoutForm) {
      if(event?.data){
        store.dispatch(setBotEventData(event.data));
      }
      store.dispatch(setCurrentScreen(screenNames.checkoutForm));
    }
  } catch (error) {}
};
