import { createSlice } from "@reduxjs/toolkit";
import { DiscountRanges, OrderHistory } from "../../types";

export interface skuDataInterface {
  allowBonus: boolean;
  brand: string;
  brandIdentifier: string;
  dailyOffer: boolean;
  discountExecution: string;
  ean: string;
  maxLevelReached: boolean;
  maxVolume: number;
  noDiscountPercentage: string;
  offInvoiceDiscountRanges: DiscountRanges[];
  offerLevel: string;
  onInvoiceDiscountRanges: DiscountRanges[];
  period: {
    endDateString: string;
    period: string;
    startDateString: string;
  };
  previouslyOrdered: boolean;
  sku: string | null;
  skuIdentifier: string | null;
  skuPrice?: number;
}
export interface FiltredApiDataInterface {
  brand: string;
  brandIdentifier: string;
  discountExecution: string;
  onInvoiceDiscountRanges: DiscountRanges[];
  offInvoiceDiscountRanges: DiscountRanges[];
  purchased: number;
  selected: number;
  offerLevel: string;
  skuData: skuDataInterface[];
  maxVolume: number;
}

interface OrderState {
  orderData: any;
  filteredApiData: {
    offerData: FiltredApiDataInterface[];
    openOrders: OrderHistory[];
    closeOrders: OrderHistory[];
    lastOrder: OrderHistory | null;
    startDate: string;
    endDate: string;
  };
  selectedBrand: FiltredApiDataInterface
}

const orderSlice = createSlice({
  name: "orderSlice",
  initialState: {
    orderData: {},
    filteredApiData: {
      offerData: [],
      openOrders: [],
      closeOrders:[],
      lastOrder: null,
      startDate: "",
      endDate: "",
    },
    selectedBrand: {}
  },
  reducers: {
    setOrderData(state, action) {
      state.orderData = action.payload;
    },
    setFilteredApiData(state, action) {
      state.filteredApiData = action.payload;
    },
    setSelectedBrand(state,action){
      state.selectedBrand = action.payload;
    }
  },
});

export default orderSlice.reducer;

export const { setOrderData, setFilteredApiData, setSelectedBrand } = orderSlice.actions;

export const OrderData = (state: { orderSlice: OrderState }) =>
  state.orderSlice.orderData;

export const FilteredApiData = (state: { orderSlice: OrderState }) =>
  state.orderSlice.filteredApiData;

export const SelectedBrand = (state: {orderSlice: OrderState}) => state.orderSlice.selectedBrand;
