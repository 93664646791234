import { Box, CircularProgress } from "@mui/material";
import { themeColors } from "../../utils/constants/themeColors";

const LoadingIcon = () => {
  return (
    <Box
      sx={{
        display: "flex",
        position: "absolute",
        height:'100%',
        alignItems: "center",
        width: "40%",
        justifyContent: "center",
        zIndex: 99,
      }}
    >
      <CircularProgress sx={{ color: themeColors.primaryType1 }} />
    </Box>
  );
};

export default LoadingIcon;
