import { images } from "../../../utils/constants/images";
import { languages } from "../../../utils/constants/languages";

export const welcomeAssests = [
  {
    language: languages.master,
    bg: images.oragneBg,
    bannerAssets: images.enBannerAssets
  },
  {
    language: languages.spanish,
    bg: images.oragneBg,
    bannerAssets: images.esBannerAssets
  },
];
