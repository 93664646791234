import { createSlice } from "@reduxjs/toolkit";
interface LoadingState {
  loading: boolean;
  bannerLoaded: boolean;
}

const loadingSlice = createSlice({
  name: "locSlice",
  initialState: {
    loading: false,
    bannerLoaded: false,
  },
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setBannerLoaded(state, action) {
      state.bannerLoaded = action.payload;
    },
  },
});

export default loadingSlice.reducer;

export const { setLoading, setBannerLoaded } = loadingSlice.actions;

export const isLoading = (state: { loadingSlice: LoadingState }) =>
  state.loadingSlice.loading;

export const BannerLoaded = (state: { loadingSlice: LoadingState }) =>
  state.loadingSlice.bannerLoaded;
