import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Locale } from "../../utils/redux";

const Footer = () => {
  const locale = useSelector(Locale);
  return (
    <Box p={"3px 7px"} color={"secondary.main"}>
      <Typography
        className="tnc-typography"
        dangerouslySetInnerHTML={{ __html: locale.initialScreen.footer }}
      ></Typography>
    </Box>
  );
};

export default Footer;
