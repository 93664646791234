import { Box, Typography } from "@mui/material";
import { themeColors } from "../../../utils/constants/themeColors";
import "./index.css";
import { useSelector } from "react-redux";
import { Locale } from "../../../utils/redux";
import { FC, useEffect, useState } from "react";
import { regAssests } from "./regAssests";

interface RegistrationScreenProps {
  event: string;
}

const RegistrationScreen: FC<RegistrationScreenProps> = ({ event }) => {
  const locale = useSelector(Locale);
  const [banner, setBanner] = useState<any>("");

  useEffect(() => {
    if (locale.language) {
      const regAssestsData = regAssests.find(
        (data) => data.language === locale.language
      );
      if (regAssestsData) {
        const bannerData = regAssestsData.assets.find(
          (ele) => ele.event === event
        );
        setBanner(bannerData?.img);
      }
    }
  }, [event, locale.language]);

  return (
    <Box className="fullHeight flex fdc">
      <Box
        className="welcome-banner"
        sx={{
          backgroundImage: `url(${banner})`,
        }}
      />
      <Box className="pt10 pb5 pl10 pr10">
        <Typography
          className="tnc-typography"
          color={themeColors.secondaryType2}
          dangerouslySetInnerHTML={{ __html: locale.initialScreen.tnc }}
        ></Typography>
      </Box>
    </Box>
  );
};

export default RegistrationScreen;
