import { createSlice } from "@reduxjs/toolkit";

interface Labels {
  title: string;
  lastOrder: string;
  orderHistory: string;
  startDate: string;
  endDate: string;
  start: string;
  end: string;
  estPrice: string;
  units: string;
  freeGoods: string;
  payTerm: string;
  pickDate: string;
  offInvoice: string;
  onInvoice: string;
  offInvoiceDiscount: string;
  onInvoiceDiscount: string;
  discAbbr: string;
  discount: string;
  value: string;
  additionalDiscount: string;
  orderDetails: string;
  products: string;
  rebates: string;
  orderNo: string;
  status: string;
  orderDate: string;
  orderedOn: string;
  statusDate: string;
  periodTotal: string;
  deliveryOn: string;
  yes: string;
  no: string;
  logOutPopupTitle: string;
  downloadOrderHistory: string;
  chooseBrands: string;
  profileDetails: string;
  associatedAccounts: string;
  notification: string;
  addAccount: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  email: string;
  profileSettings: string;
  accountNumber: string;
  legalId: string;
  attachInvoice: string;
  emptyOrder: string;
}

interface Buttons {
  back: string;
  cancel: string;
  confirm: string;
  continue: string;
  placeNewOrder: string;
  logOut: string;
  chooseBrands: string;
  checkout: string;
  addAnotherAccount: string;
  accept: string;
  decline: string;
}

interface Snackbars {
  exceedMaxLimit: string;
  selectDate: string;
  maxReached: string;
  accountSelected: string;
}
interface CheckoutForm {
  deleteOrderMessage: string;
  ok_button: string;
  order_details: string;
  period_orders: string;
  editing_order: string;
  noOfPhar: string;
  wholesaler_placeholder: string;
  wholesaler_error: string;
  date_error: string;
  filter_placeholder: string;
  date_placeholder: string;
  cleara_button: string;
  no_date_available: string;
  products_th: string;
  units_th: string;
  discount: string;
  total_value: string;
  table_discount: string;
  total_discount: string;
  final_value: string;
  confirm_button: string;
  cancel_button: string;
  unit_select_error: string;
  order_confirm_message: string;
  cancel_message: string;
  order_success_message: string;
  order_no_history: string;
  date_history: string;
  order_details_history: string;
  sku_th_history: string;
  units_th_history: string;
  on_invoice_discount_history: string;
  placing_new_order: string;
  new_order_button: string;
  no_of_phar_error: string;
  cancel_order: string;
  keep_order: string;
  confirm_pop_up_button: string;
  go_back: string;
  sku_th: string;
  total_units: string;
  price: string;
  currency: string;
  open_order: string;
  close_order: string;
  edit_tooltip: string;
  delete_tooltip: string;
  download_tooltip: string;
  more_details_tooltip: string;
  no_order_history: string;
  order_delete_success: string;
  filter_error: string;
  api_error: string;
  maxVolumeError: string;
  edit_button: string;
  drop_changes: string;
  drop_changes_yes: string;
  drop_changes_no: string;
}

interface InitialScreen {
  welcomeBanner: string;
  topBanner: string;
  footer: string;
  tnc: string;
}

export interface LanguageLocalization {
  language: string;
  labels: Labels;
  buttons: Buttons;
  snackbars: Snackbars;
  initialScreen: InitialScreen;
  checkoutForm: CheckoutForm;
}

interface LocState {
  localeApiData: {
    defaultLang: string;
    localization: LanguageLocalization[];
  };
  locale: LanguageLocalization;
  loc: string;
}

const locSlice = createSlice({
  name: "locSlice",
  initialState: {
    locale: {
      language: "",
      labels: {
        title: "",
        lastOrder: "",
        orderHistory: "",
        startDate: "",
        endDate: "",
        start: "",
        end: "",
        estPrice: "",
        units: "",
        freeGoods: "",
        payTerm: "",
        pickDate: "",
        offInvoice: "",
        onInvoice: "",
        offInvoiceDiscount: "",
        onInvoiceDiscount: "",
        discAbbr: "",
        discount: "",
        value: "",
        additionalDiscount: "",
        orderDetails: "",
        products: "",
        rebates: "",
        orderNo: "",
        status: "",
        orderDate: "",
        orderedOn: "",
        statusDate: "",
        periodTotal: "",
        deliveryOn: "",
        yes: "",
        no: "",
        logOutPopupTitle: "",
        downloadOrderHistory: "",
        chooseBrands: "",
        profileDetails: "",
        associatedAccounts: "",
        notification: "",
        addAccount: "",
        firstName: "",
        lastName: "",
        mobileNumber: "",
        email: "",
        profileSettings: "",
        accountNumber: "",
        legalId: "",
        attachInvoice: "",
        emptyOrder: "",
      },
      buttons: {
        back: "",
        cancel: "",
        confirm: "",
        continue: "",
        placeNewOrder: "",
        logOut: "",
        chooseBrands: "",
        checkout: "",
        addAnotherAccount: "",
        accept: "",
        decline: "",
      },
      snackbars: {
        exceedMaxLimit: "",
        selectDate: "",
        maxReached: "",
        accountSelected: "",
      },
      initialScreen: { welcomeBanner: "", topBanner: "", footer: "", tnc: "" },
      checkoutForm: {
        deleteOrderMessage: "",
        ok_button: "",
        order_details: "",
        period_orders: "",
        editing_order: "",
        noOfPhar: "",
        wholesaler_placeholder: "",
        wholesaler_error: "",
        date_error: "",
        filter_placeholder: "",
        date_placeholder: "",
        cleara_button: "",
        no_date_available: "",
        products_th: "",
        units_th: "",
        discount: "",
        total_value: "",
        table_discount: "",
        total_discount: "",
        final_value: "",
        confirm_button: "",
        cancel_button: "",
        unit_select_error: "",
        order_confirm_message: "",
        cancel_message: "",
        order_success_message: "",
        order_no_history: "",
        date_history: "",
        order_details_history: "",
        sku_th_history: "",
        units_th_history: "",
        on_invoice_discount_history: "",
        placing_new_order: "",
        new_order_button: "",
        no_of_phar_error: "",
        cancel_order: "",
        keep_order: "",
        confirm_pop_up_button: "",
        go_back: "",
        sku_th: "",
        total_units: "",
        price: "",
        currency: "",
        open_order: "",
        close_order: "",
        edit_tooltip: "",
        delete_tooltip: "",
        download_tooltip: "",
        more_details_tooltip: "",
        no_order_history: "",
        order_delete_success: "",
        filter_error: "",
        api_error: "",
        maxVolumeError: "",
        edit_button: "",
        drop_changes: "",
        drop_changes_yes: "",
        drop_changes_no: "",
      },
    },
    loc: null
  },
  reducers: {
    setLocale(state, action) {
      state.locale = action.payload;
    },
    setLoc(state, action) {
      state.loc = action.payload;
    }
  },
});

export default locSlice.reducer;

export const { setLocale, setLoc } = locSlice.actions;

export const Locale = (state: { locSlice: LocState }) => state.locSlice.locale;
export const Loc = (state: { locSlice: LocState }) => state.locSlice.loc;