/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import CustomAccordian from "../../../components/CustomAccordian/CustomAccordian";
import "./index.css";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useDispatch, useSelector } from "react-redux";
import {
  Loc,
  Locale,
  setLoading,
  setUserProfile,
  User,
} from "../../../utils/redux";
import axiosInstance from "../../../utils/axios";
import { URLS } from "../../../utils/constants/urls";
import { ProfileDetails } from "../../../utils/redux/reducer/authentication-slice";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PendingIcon from "@mui/icons-material/Pending";
import ImageIcon from "@mui/icons-material/Image";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { themeColors } from "../../../utils/constants/themeColors";
import ToggleButton from "../../../components/ToggleButton/ToggleButton";
import { sendEvent } from "../../../utils/helperFunctions/sendEvent";
import { eventNames } from "../../../utils/constants/events";

const StatusRibbon = ({ status }: { status: string }) => {
  return (
    <Box
      bgcolor={
        status === "VERIFIED"
          ? "success.main"
          : status === "PENDING_VERIFICATION"
          ? "warning.light"
          : "error.main"
      }
      height={"17px"}
      width={"5px"}
      borderRadius={"5px"}
    />
  );
};

const StatusMark = ({ status }: { status: string }) => {
  return (
    <Box width={"45px"} height={"20px"}>
      {status === "VERIFIED" ? (
        <CheckCircleIcon
          className="pointer"
          sx={{ color: "success.main" }}
          data-tooltip-id="custom-tooltip"
          data-tooltip-content={status}
        />
      ) : status === "PENDING_VERIFICATION" ? (
        <PendingIcon
          className="pointer"
          sx={{ color: "warning.light" }}
          data-tooltip-id="custom-tooltip"
          data-tooltip-content={status}
        />
      ) : (
        <CancelIcon
          className="pointer"
          sx={{ color: "error.main" }}
          data-tooltip-id="custom-tooltip"
          data-tooltip-content={status}
        />
      )}
    </Box>
  );
};

const UserProfile = () => {
  const dispatch = useDispatch();
  const user = useSelector(User);
  const loc = useSelector(Loc);
  const locale = useSelector(Locale);
  const profileData = useSelector(ProfileDetails);
  const getProfileData = async () => {
    try {
      dispatch(setLoading(true));
      const { data } = await axiosInstance.get(
        URLS.getProfile(user.phone, loc)
      );
      dispatch(setLoading(false));
      if (data) {
        dispatch(setUserProfile(data));
      }
    } catch (error) {
      dispatch(setLoading(false));
      console.log(error);
    }
  };
  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <Box className="p20">
      <CustomAccordian
        defaultOpen={true}
        title={
          <Typography
            color={"primary"}
            fontWeight={500}
            fontSize={{ lg: 15, xl: 18 }}
          >
            {locale.labels.profileDetails}
          </Typography>
        }
      >
        <Box className="flex ai-c g15">
          <Box className="flex fdc g10">
            <Typography className="profile-detail-title" color="secondary.main">
              {locale.labels.firstName}:
            </Typography>
            <Typography className="profile-detail-title" color="secondary.main">
              {locale.labels.lastName}:
            </Typography>
            <Typography className="profile-detail-title" color="secondary.main">
              {locale.labels.mobileNumber}:
            </Typography>
            <Typography className="profile-detail-title" color="secondary.main">
              {locale.labels.email}:
            </Typography>
          </Box>
          <Box className="f1 flex">
            <Box className="flex fdc g10">
              <Typography className="profile-detail-text">
                {profileData.profileDetails.firstName}
              </Typography>

              <Typography className="profile-detail-text">
                {profileData.profileDetails.lastName}
              </Typography>

              <Typography className="profile-detail-text">
                {profileData.profileDetails.phoneNumber}
              </Typography>

              <Typography className="profile-detail-text">
                {profileData.profileDetails.email}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CustomAccordian>

      <Box
        bgcolor={"white"}
        className="br5"
        boxShadow={"0px 3px 12px rgba(84, 79, 64, 0.1)"}
        p={"10px 18px"}
      >
        <Typography color={"primary"} fontWeight={500} fontSize={{ lg: 15, xl: 18 }}>
          {locale.labels.associatedAccounts}
        </Typography>
        <Box className="mt10">
          {profileData.associatedAccounts &&
            profileData.associatedAccounts.map((wholesaleAccount) => {
              return (
                <Box
                  key={wholesaleAccount.wholeSalerCode}
                  borderTop={"0.5px solid #e6e6e6"}
                  paddingTop={"3px"}
                >
                  <CustomAccordian
                    padding={"5px"}
                    expandedHeight={20}
                    defaultOpen={true}
                    customHeight={20}
                    title={
                      <Box className="flex ai-c jc-sb fullWidth">
                        <Typography fontSize={{lg:14.5, xl:16}}>
                          {wholesaleAccount.wholeSalerName}
                        </Typography>
                        <Typography
                          color={
                            wholesaleAccount.wholeSalerStatus === "ACTIVE"
                              ? "success.main"
                              : "error.main"
                          }
                          fontWeight={500}
                          fontSize={{lg:12.5, xl:13.5}}
                          mr={"10px"}
                        >
                          {wholesaleAccount.wholeSalerStatus}
                        </Typography>
                      </Box>
                    }
                    shadow={false}
                  >
                    <Box className="flex">
                      <Box width={"25px"} />
                      <Box className="flex f1" gap={"3px"}>
                        <Typography
                          color="primary"
                          fontWeight={500}
                          className="f2"
                          fontSize={{lg:12, xl:14}}
                        >
                          {locale.labels.accountNumber}
                        </Typography>
                        <Typography
                          color="primary"
                          fontWeight={500}
                          className="f1"
                          fontSize={{lg:12, xl:14}}
                        >
                          {locale.labels.legalId}
                        </Typography>
                        <Typography
                          color="primary"
                          fontWeight={500}
                          width={"45px"}
                          fontSize={{lg:12, xl:14}}
                        >
                          {locale.labels.status}
                        </Typography>
                      </Box>
                      <Box width={"85px"} />
                    </Box>
                    {wholesaleAccount.accounts &&
                      wholesaleAccount.accounts.map((account, index) => {
                        return (
                          <Box
                            className="flex ai-c mt5 pt5"
                            borderTop={"0.5px solid #e6e6e6"}
                            key={index}
                            pt={'3px'}
                          >
                            <Box width={"25px"}>
                              <StatusRibbon status={account.accountStatus} />
                            </Box>
                            <Box className="flex f1 ai-c" gap={"3px"}>
                              <Box className="f2 flex g5">
                                <Box>
                                  <Typography fontSize={{lg:13,xl:15}}>
                                    {account.accountNumber}
                                  </Typography>
                                </Box>
                              </Box>
                              <Typography className="f1" fontSize={{lg:13,xl:15}}>
                                {account.legalIdCode}
                              </Typography>
                              <StatusMark status={account.accountStatus} />
                            </Box>
                            <Box width={"85px"} className="flex jc-c g5">
                              <ImageIcon
                                onClick={() => {
                                  window.open(account.invoice, "_blank");
                                }}
                                color="primary"
                                className="pointer"
                              />
                              <AttachFileIcon
                                color="primary"
                                className="pointer"
                                onClick={() => {
                                  sendEvent(eventNames.attachInvoice, {
                                    accountNumber: account.accountNumber,
                                    accountStatus: account.accountStatus,
                                    invoice: account.invoice,
                                    legalIdCode: account.legalIdCode,
                                    wholeSalerCode:
                                      wholesaleAccount.wholeSalerCode,
                                    wholeSalerName:
                                      wholesaleAccount.wholeSalerName,
                                    wholeSalerStatus:
                                      wholesaleAccount.wholeSalerStatus,
                                    accounts: account,
                                  });
                                }}
                              />
                              <DeleteOutlineIcon
                                color="primary"
                                className="pointer"
                                onClick={() => {
                                  sendEvent(
                                    eventNames.deleteAssociatedAccount,
                                    {
                                      accountNumber: account.accountNumber,
                                      accountStatus: account.accountStatus,
                                      invoice: account.invoice,
                                      legalIdCode: account.legalIdCode,
                                      wholeSalerCode:
                                        wholesaleAccount.wholeSalerCode,
                                      wholeSalerName:
                                        wholesaleAccount.wholeSalerName,
                                      wholeSalerStatus:
                                        wholesaleAccount.wholeSalerStatus,
                                      accounts: account,
                                    }
                                  );
                                }}
                              />
                            </Box>
                          </Box>
                        );
                      })}
                  </CustomAccordian>
                </Box>
              );
            })}

          <Box textAlign={"center"}>
            <CustomButton
              onClick={() => {
                sendEvent(eventNames.addAssociatedaccounts, {});
              }}
              align="center"
              width="105px"
              padding="3px"
            >
              <Typography>{locale.labels.addAccount}</Typography>
            </CustomButton>
          </Box>
        </Box>
      </Box>

      <Box className="mt10">
        <CustomAccordian
          title={
            <Typography color={"primary"} fontWeight={500} fontSize={{ lg: 15, xl: 18 }}>
              User Preferences
            </Typography>
          }
        >
          <Typography color="secondary.main" fontSize={{ lg: 13, xl: 14 }}>
            Content Preference
          </Typography>
          <Box className="flex mt5 g10" flexWrap={"wrap"}>
            <Box className="flex ai-c f1 g5 sameline">
              <Typography fontSize={{ lg: 13, xl: 15 }}>Offer Information</Typography>
              <ToggleButton />
            </Box>
            <Box className="flex ai-c f1 g5 sameline">
              <Typography fontSize={{ lg: 13, xl: 15 }}>Marketing Campaigns</Typography>
              <ToggleButton />
            </Box>
            <Box className="flex ai-c f1 g5 sameline">
              <Typography fontSize={{ lg: 13, xl: 15 }}>Transactional</Typography>
              <ToggleButton />
            </Box>
          </Box>
          <Typography color="secondary.main" className="pt15" fontSize={{ lg: 13, xl: 14 }}>
            Notifications Channels
          </Typography>
          <Box className="flex mt5 sameline g5" flexWrap={"wrap"}>
            <Box className="flex ai-c g10">
              <Typography fontSize={{ lg: 13, xl: 15 }}>E-Mail</Typography>
              <ToggleButton />
            </Box>
            <Box className="flex ai-c f1 g10 jc-c">
              <Typography fontSize={{ lg: 13, xl: 15 }}>Instant Messaging</Typography>
              <ToggleButton />
            </Box>
            <Box className="flex ai-c g10">
              <Typography fontSize={{ lg: 13, xl: 15 }}>SMS</Typography>
              <ToggleButton />
            </Box>
          </Box>
          <Box className="tar mt20">
            <CustomButton
              background={themeColors.secondaryType2}
              hoverBackground={themeColors.secondaryType2}
              onClick={() => {
                sendEvent(eventNames.deleteUser, {});
              }}
              align="center"
              width="85px"
              padding="3px"
            >
              <Typography fontSize={11}>UNREGISTER</Typography>
            </CustomButton>
          </Box>
        </CustomAccordian>
      </Box>
    </Box>
  );
};

export default UserProfile;
