import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function EditIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_138_2109)">
          <path
            d="M17.2372 9.00798H18.0421V9.32421C18.0421 12.3688 18.0421 15.4132 18.0421 18.4574C18.0421 19.467 17.5063 19.9996 16.4911 19.9996C11.5021 19.9996 6.51331 19.9996 1.52466 19.9996C0.540671 19.9996 0.00390625 19.4634 0.00390625 18.482C0.00390625 13.4759 0.00390625 8.4702 0.00390625 3.46483C0.00390625 2.50898 0.556533 1.96045 1.51698 1.96045H10.7929V2.76585H1.54154C0.978679 2.76585 0.804192 2.93932 0.804192 3.49553V18.4635C0.804192 19.0264 0.978167 19.1994 1.53489 19.1999H16.5009C17.053 19.1999 17.2372 19.0162 17.2372 18.4656C17.2372 15.4214 17.2372 12.3771 17.2372 9.33291V9.00798Z"
            fill="#F36633"
            stroke="#F36633"
            strokeWidth="1.10"
          />
          <path
            d="M17.624 0C17.887 0 18.1541 0.127923 18.3813 0.358185C18.8249 0.806939 19.2742 1.25057 19.7189 1.6983C20.2065 2.18953 20.2055 2.83887 19.7117 3.33214C16.6538 6.38745 13.5862 9.43509 10.5447 12.5073C10.0493 13.0197 9.44467 13.4136 8.7758 13.6596C7.6071 14.09 6.46142 14.5745 5.30448 15.0284C5.22568 15.0591 5.07473 15.0637 5.04301 15.0197C5.01913 14.9764 5.00482 14.9286 5.00102 14.8793C4.99723 14.8301 5.00404 14.7806 5.021 14.7342C5.59922 13.3042 6.18374 11.8765 6.77457 10.4513C6.82836 10.3272 6.90529 10.2145 7.00125 10.1192C10.242 6.87203 13.4842 3.62636 16.728 0.382233C16.9603 0.147878 17.2269 0.00102338 17.624 0ZM9.55511 12.2622L17.8558 3.96971L16.1191 2.21256C13.3539 4.97569 10.585 7.74377 7.81229 10.5168L9.55511 12.2622ZM18.5358 3.392C18.7569 3.16379 18.9856 2.93711 19.201 2.69918C19.3146 2.57432 19.3034 2.42695 19.1867 2.30773C18.72 1.83493 18.2554 1.35956 17.7719 0.904668C17.6982 0.835078 17.4797 0.806425 17.4137 0.861688C17.1578 1.07506 16.9337 1.32989 16.7132 1.55452C17.3226 2.16855 17.9151 2.76569 18.5358 3.392ZM6.26902 13.7835L8.79473 12.7775L7.29701 11.271L6.26902 13.7835Z"
            fill="#F36633"
            stroke="#F36633"
            strokeWidth="0.50"
          />
        </g>
        <defs>
          <clipPath id="clip0_138_2109">
            <rect width="19.9258" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
