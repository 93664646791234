import { Box } from "@mui/material";
import React from "react";
import { demoTNCData } from "./demoData";
import { themeColors } from "../../../utils/constants/themeColors";

const TermsCondition = () => {
  let tncData = demoTNCData;
  return <Box className="p20">
    {tncData.map((termData)=>{
      return <Box>
        <span dangerouslySetInnerHTML={{__html:termData.title}} style={{color:themeColors.primaryType1, fontSize:'13px', fontWeight:'600'}}></span>
        
        <p style={{fontSize:'13px'}} dangerouslySetInnerHTML={{__html:termData.desc}}></p>
      </Box>
    })}
  </Box>;
};

export default TermsCondition;
