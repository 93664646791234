export const eventNames = {
  welcomeScreen: "welcome-screen",
  userLogin: "user-login",
  botLoadLanguage: "bot-load-language",
  loadLanguage: "load-language",
  language: "language",
  userWelcomeScreen: "userwelcome-screen",
  orderHistory: "orderhistory-screen",
  regUnfilledStep: "welcome-screen-unfilled-steps",
  regStep1: "welcome-screen-filled-step1",
  regStep2: "welcome-screen-filled-step2",
  regStep3: "welcome-screen-filled-step3",
  termCondition: "termsui-screen",
  addAssociatedaccounts: 'addaccount-associatedaccounts',
  attachInvoice: 'attach-invoice-clicked',
  deleteAssociatedAccount: 'delete-associated-account',
  deleteUser: 'delete-user',
  checkoutForm: 'show-form-in-left-panel'
};
