import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { themeColors } from "../../utils/constants/themeColors";
import { FC } from "react";

interface CustomButtonProps {
  children: any;
  align?: string;
  width?: string;
  fontWeight?: number;
  disabled?: boolean;
  background?: string;
  hoverBackground?: string;
  color?: string;
  onClick: () => void;
  border?: string;
  fontSize?: number;
  boxShadow?: string;
  padding?: string;
}

const CustomButton: FC<CustomButtonProps> = ({
  children,
  align = "space-between",
  width = "200px",
  fontWeight = 400,
  disabled = false,
  background = themeColors.primaryType1,
  hoverBackground = themeColors.primaryType2,
  color = "white",
  border = "",
  fontSize = 20,
  boxShadow = "0 2px 6px rgba(0,0,0,.35)",
  padding = "5px",
  onClick,
}) => {
  const CheckoutButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color,
    fontSize,
    fontWeight,
    padding,
    border,
    textTransform: "none",
    width,
    minWidth: width,
    justifyContent: align,
    boxShadow,
    backgroundColor: disabled ? "grey" : background,
    "&:hover": {
      backgroundColor: disabled ? "grey" : hoverBackground,
    },
  }));
  return (
    <CheckoutButton onClick={onClick} disabled={disabled}>
      {children}
    </CheckoutButton>
  );
};

export default CustomButton;
