import { configureStore } from "@reduxjs/toolkit";
import authenticationSlice from "../reducer/authentication-slice";
import locSlice from "../reducer/loc-slice";
import orderSlice from "../reducer/order-slice";
import loadingSlice from "../reducer/loading-slice";
const store = configureStore({
  reducer: {
    locSlice,
    authenticationSlice,
    orderSlice,
    loadingSlice,
  },
});

export default store;
