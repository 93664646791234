import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/material";
import "./styles.css"

interface DialogBoxProps {
  open: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  children: any;
}

const DialogBox: React.FC<DialogBoxProps> = ({ open, setOpen, children }) => {
  const handleClose = () => {
    if(setOpen){
      setOpen(false);
    }
  };

  return (
    <Box>
      <Dialog onClose={handleClose} open={open}>
        <Box>
          {children}
        </Box>
      </Dialog>
    </Box>
  );
};

export default DialogBox;
